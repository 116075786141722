import React from 'react'
import HotelsForm from '@/components/Hotels/SearchForm/Form'
import FlightsForm from '@/components/Flights/SearchForm/Form'
import CarsForm from '@/components/Cars/SearchForm/Form'
import PackagesForm from '@/components/Packages/SearchForm/Form'
import CruisesForm from '@/components/Cruises/SearchForm/Form'
import { onSubmit as onSubmitVP } from '@/components/Packages/SearchForm/utils'
import { handleSubmitForCars } from '@/components/Cars/SearchForm/utils'
import { onSubmit as onSubmitFlight } from '@/components/Flights/SearchForm/utils'
import { handleSubmitForHotels } from '@/components/Hotels/SearchForm/utils'
import type { LOCATION_SEARCH_TYPE, ProductType } from '@/types'
import type { SearchFormProductHeadlineType } from '../types'
import {
  CarsBumper,
  CruiseBumper,
  FlightsBumper,
  HotelsBumper,
  VacationBumper
} from '../Bumpers'

export default function getProductCategory(
  productType: ProductType,
  values: SearchFormProductHeadlineType,
  setLocation: (key: LOCATION_SEARCH_TYPE | undefined) => void,
  showMultiHotel?: boolean
) {
  switch (productType) {
    case 'flights':
      return {
        component: (
          <FlightsForm onSubmit={onSubmitFlight} isLandingPage={false} />
        ),
        bumper: <FlightsBumper />,
        headline: values.flights.headline,
        mobileHeadline: values.flights.mobileHeadline
      }
    case 'cars':
      return {
        component: <CarsForm onSubmit={handleSubmitForCars} />,
        bumper: <CarsBumper />,
        headline: values.cars.headline,
        mobileHeadline: values.cars.mobileHeadline
      }
    case 'vacations':
      return {
        component: <PackagesForm onSubmit={onSubmitVP} pageName="homepage" />,
        bumper: <VacationBumper />,
        headline: values.vacations.headline,
        mobileHeadline: values.vacations.mobileHeadline
      }

    case 'cruises':
      return {
        component: <CruisesForm />,
        bumper: <CruiseBumper richText={values.cruises.bumper} />,
        headline: values.cruises.headline,
        mobileHeadline: values.cruises.mobileHeadline
      }

    default:
      return {
        component: (
          <HotelsForm
            isLandingPage={false}
            onSubmit={handleSubmitForHotels}
            setLocation={setLocation}
            showMultiHotel={showMultiHotel}
          />
        ),
        bumper: <HotelsBumper />,
        headline: values.hotels.headline,
        mobileHeadline: values.hotels.mobileHeadline
      }
  }
}
