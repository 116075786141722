import React from 'react'
import CrossSell from '@/components/CrossSell/CrossSell'
import { Box } from 'pcln-design-system'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'
import TripActivity from '@/components/TripActivity/TripActivity'
import type { TripGrouping as TripGroupingProps } from './types'
import GroupedTrip from './GroupedTrip'

export default function TripGrouping({
  tripGroupingBooking,
  tripGroupingAbandonedSelection,
  defaultAbandonedSelection,
  isLandingPage,
  growthLoopAudiences
}: TripGroupingProps) {
  const tripActivityProductType = ['STAY', 'FLY', 'DRIVE'] as const
  if (tripGroupingBooking) {
    if (tripGroupingAbandonedSelection) {
      return (
        <>
          <Box mb={4}>
            <GroupedTrip
              isLandingPage={isLandingPage}
              booking={tripGroupingBooking}
              abandonedSelection={tripGroupingAbandonedSelection}
            />
          </Box>
          <TripActivity
            productType={tripActivityProductType}
            analyticsCategory={ANALYTICS_CATEGORY_HOME}
            shouldUseSmallerHeading
            abandonedSelectionData={defaultAbandonedSelection}
            hideAbandonedSelection
          />
        </>
      )
    }
    return (
      <>
        <Box mb={4}>
          <CrossSell
            booking={tripGroupingBooking}
            growthLoopAudiences={growthLoopAudiences}
          />
        </Box>
        <TripActivity
          productType={tripActivityProductType}
          analyticsCategory={ANALYTICS_CATEGORY_HOME}
          shouldUseSmallerHeading
          abandonedSelectionData={defaultAbandonedSelection}
          hideAbandonedSelection={false}
        />
      </>
    )
  }

  return (
    <TripActivity
      productType={tripActivityProductType}
      analyticsCategory={ANALYTICS_CATEGORY_HOME}
      shouldUseSmallerHeading={false}
      abandonedSelectionData={defaultAbandonedSelection}
      hideAbandonedSelection={Boolean(tripGroupingAbandonedSelection)}
    />
  )
}
