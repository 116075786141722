import analytics from '@/shared-utils/analytics'
import getCookieByName from '@/shared-utils/get-cookie-by-name'
import hasGraphErrors from '@/shared-utils/graph-utils'
import {
  abandonedHotelQuery,
  abandonedRentalCarQuery,
  abandonedFlightQuery
} from '@/constants/queryFragments'

import type { TripGroupingAndAbandonedSelectionApiResponse } from '@/components/TripGrouping/types'

import isAbortSignalTimeoutSupported from '@/shared-utils/is-abortSignal-supported'
import type { GraphResponse } from '@/types'
import config from 'isomorphic-config'
import getAuthorizationToken from './get-authorization-token'
import { jsonContentTypeHeader } from '../server/constants'

const query = `query GetTripGroupingsAndAbandonedSelections($authToken: String!, $cguid: String!) {
  abandonedSelections: getAbandonedItemsByAuthTokenAndCguid(authToken: $authToken, cguid: $cguid) {
    ${abandonedHotelQuery}
    ${abandonedRentalCarQuery}
    ${abandonedFlightQuery}
    }
  tripGroupings: getTripGroupingsByAuthTokenAndCguid(authToken: $authToken, cguid: $cguid) {
    bookingActivityData {
      ... on TripGroupingBookedHotel {
        productType
        productId
        travelStartDateTime
        travelEndDateTime
        hotelInfo {
          ... on RtlHotel {
            thumbnail {
                source
            }
          }
        }
        hotelName
        numberOfRooms
        numberOfDays
        cityName
        stateCode
        countryCode
        countryName
        creationDateTime
        itineraryUrl
        offerToken
        offerId
      }
      ... on TripGroupingBookedRentalCar {
        productType
        productId
        travelStartDateTime
        travelEndDateTime
        pickupLocationCityName
        pickupLocationCountryCode
        pickupLocationProvince
        returnLocationCityName
        returnLocationCountryCode
        returnLocationProvince
        creationDateTime
        itineraryUrl
        offerToken
        offerId
      }
      ... on TripGroupingBookedFlight {
        productType
        productId
        travelStartDateTime
        travelEndDateTime
        creationDateTime
        itineraryUrl
        offerToken
        offerId
        tripType
        originAirportInfo {
          city
          code
          name
          state
          country
        }
        destinationAirportInfo {
            city
            code
            name
            state
            country
        }
        airlineMarketingCode
      }
    }
    selectionActivityData {
      ${abandonedHotelQuery}
      ${abandonedRentalCarQuery}
      ${abandonedFlightQuery}
    }
  }
 }
`

type TripGroupingGraphResponse =
  GraphResponse<TripGroupingAndAbandonedSelectionApiResponse>

function hasRedeemablePayload(
  payload: unknown
): payload is TripGroupingGraphResponse {
  if (payload && payload instanceof Object) {
    if ('data' in payload && payload.data && typeof payload.data === 'object') {
      return (
        ('abandonedSelections' in payload.data &&
          Array.isArray(payload.data.abandonedSelections)) ||
        ('tripGroupings' in payload.data &&
          Array.isArray(payload.data.tripGroupings))
      )
    }
  }
  return false
}

function isValidGraphQLResponse(
  payload: unknown
): payload is TripGroupingGraphResponse {
  if (payload && payload instanceof Object) {
    if ('errors' in payload && Array.isArray(payload.errors)) {
      return true
    }
    if (
      'data' in payload &&
      payload.data &&
      typeof payload.data === 'object' &&
      'abandonedSelections' in payload.data &&
      (payload.data.abandonedSelections === null ||
        Array.isArray(payload.data.abandonedSelections))
    ) {
      return true
    }
    if (
      'data' in payload &&
      payload.data &&
      typeof payload.data === 'object' &&
      'tripGroupings' in payload.data &&
      (payload.data.tripGroupings === null ||
        Array.isArray(payload.data.tripGroupings))
    ) {
      return true
    }
  }
  return false
}
const { url, timeout } = config.client['pcln-graph']
async function fetchTripGroupings(
  cguid: string,
  appName: string,
  appVersion: string
) {
  const authToken = getCookieByName('dmc') || ''

  const commonOptions = {
    method: 'POST',
    signal: isAbortSignalTimeoutSupported()
      ? AbortSignal.timeout(timeout)
      : undefined
  }

  const options = {
    ...commonOptions,
    headers: {
      authToken,
      ...jsonContentTypeHeader,
      'apollographql-client-name': appName,
      'apollographql-client-version': appVersion,
      Authorization: getAuthorizationToken()
    },
    body: JSON.stringify({
      query,
      variables: {
        authToken,
        cguid
      }
    })
  }

  const requestUrl = `${url}?gqlOp=getTripGroupings`

  try {
    const response = await fetch(requestUrl, options)
    const { status, url: urlValue } = response
    if (!response.ok) {
      analytics.logError({
        message: 'The getTripGroupings graph query response status is > 299',
        url: urlValue,
        status
      })
    } else {
      const payload = await response.json()
      if (isValidGraphQLResponse(payload)) {
        const payloadHasGraphErrors =
          hasGraphErrors<TripGroupingAndAbandonedSelectionApiResponse>(payload)
        const payloadHasRedeemableData = hasRedeemablePayload(payload)
        if (payloadHasGraphErrors) {
          const firstErrorMessage = payload.errors?.[0]?.message
          const errorMessage = firstErrorMessage || 'Unknown error'
          const errorObjectAsString = JSON.stringify(payload.errors)
          const message =
            'The getTripGroupings graph query response has returned errors.'
          analytics.logError({
            message,
            errorMessage,
            url: requestUrl,
            appName,
            appVersion,
            errorObjectAsString,
            hasRedeemablePayload: payloadHasRedeemableData
          })
        }
        if (
          payload.data &&
          (!payloadHasGraphErrors || payloadHasRedeemableData)
        ) {
          return payload.data
        }
      } else {
        analytics.logError({
          message: `The getTripGroupings graph query response is not as per contract`,
          payload: JSON.stringify(payload)
        })
      }
    }
    return { tripGroupings: [], abandonedSelections: [] }
  } catch (error) {
    const errorMessage =
      error && error instanceof Error
        ? `${error.message} Error Name: ${error.name}`
        : 'Unknown error'
    analytics.logError({
      message: 'Failed to call the getTripGroupings graph query.',
      errorMessage,
      error: JSON.stringify(error)
    })
    return { tripGroupings: [], abandonedSelections: [] }
  }
}

export default fetchTripGroupings
