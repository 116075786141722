import useContentfulSearchFormHeadlines from '@/hooks/useContentfulSearchFormHeadlines'
import { HEADERS } from '@/components/SearchForm/constants'
import type { ProductType } from '@/types'

const useHeadlineValues = (currentTab: ProductType) => {
  const hotels = useContentfulSearchFormHeadlines(
    'searchFormHotels',
    currentTab === 'hotels'
  )
  const flights = useContentfulSearchFormHeadlines(
    'searchFormFlights',
    currentTab === 'flights'
  )
  const vacations = useContentfulSearchFormHeadlines(
    'searchFormVacations',
    currentTab === 'vacations'
  )
  const cars = useContentfulSearchFormHeadlines(
    'searchFormCars',
    currentTab === 'cars'
  )

  const cruises = useContentfulSearchFormHeadlines(
    'searchFormCruises',
    currentTab === 'cruises'
  )

  return {
    hotels: hotels
      ? {
          headline: hotels.headline ?? '',
          mobileHeadline: hotels.mobileHeadline ?? ''
        }
      : HEADERS.hotels,
    flights: flights
      ? {
          headline: flights.headline ?? '',
          mobileHeadline: flights.mobileHeadline ?? ''
        }
      : HEADERS.flights,
    vacations: vacations
      ? {
          headline: vacations.headline ?? '',
          mobileHeadline: vacations.mobileHeadline ?? ''
        }
      : HEADERS.vacations,
    cars: cars
      ? {
          headline: cars.headline ?? '',
          mobileHeadline: cars.mobileHeadline ?? ''
        }
      : HEADERS.cars,
    cruises: cruises
      ? {
          headline: cruises.headline ?? '',
          mobileHeadline: cruises.mobileHeadline ?? '',
          bumper: cruises.bumper
        }
      : HEADERS.cruises
  }
}

export default useHeadlineValues
