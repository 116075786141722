import React, { useRef, useEffect } from 'react'
import { Dialog, Grid, Box, Flex, Text } from 'pcln-design-system'
import { Search as SearchIcon } from 'pcln-icons'
import SearchFormProvider from '@/context/SearchFormContext'
import type { ProductType } from '@/types'
import { dialogHeadings, searchHeaders } from './constants'
import StyledButton from './utils/Button.styled'

type SearchFormDialogProps = {
  openForm: boolean
  onOpenChange: (open: boolean) => void
  currentTab: ProductType
  formState: {
    component: React.ReactNode
    bumper: React.ReactNode
  }
  onInputClick?: () => void
}

function IconLeft() {
  return <SearchIcon ml={-2} mr={2} color="primary.base" />
}

export default function SearchFormDialog({
  openForm,
  onOpenChange,
  currentTab,
  formState,
  onInputClick
}: SearchFormDialogProps) {
  const dialogRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if (dialogRef.current && openForm) {
        dialogRef.current.setAttribute('tabIndex', '0')
        dialogRef.current.focus()
      }
    }, 0)
  }, [openForm])

  const dialogProps = {
    ariaDescription: `${searchHeaders[currentTab]} in Dialog`,
    ariaTitle: searchHeaders[currentTab],
    borderRadius: '2xl',
    children: (
      <Grid p={[3, null, null, null, '24px']} gap={3}>
        <SearchFormProvider>
          <Box>
            <Box>{formState.component}</Box>
          </Box>
          <Box mt={3}>{formState.bumper}</Box>
        </SearchFormProvider>
      </Grid>
    ),
    fullWidth: true,
    headerColorScheme: 'heading',
    headerIcon: false,
    headerShowCloseButton: true,
    scrimDismiss: true,
    // Add focus on top element to fix scrolling issues on longer search forms
    headerContent: <Box ref={dialogRef}>{searchHeaders[currentTab]}</Box>,
    scrimColor: 'dark',
    sheet: true,
    showCloseButton: false,
    onOpenChange,
    size: 'full',
    triggerNode: (
      <Box pt={2} px={3}>
        <StyledButton
          pl={2}
          width={1}
          size="extraLarge"
          variation="white"
          borderRadius="xl"
          py={0}
          IconLeft={IconLeft}
          onClick={onInputClick}
        >
          <Flex width="100%" justifyContent="flex-start" alignItems="center">
            <Flex flexDirection="column">
              <Text color="text.base" fontWeight="normal">
                {dialogHeadings[currentTab]}
              </Text>
            </Flex>
          </Flex>
        </StyledButton>
      </Box>
    ),
    overflow: 'auto',
    shouldDisableTabIndex: true
  } as const

  return <Dialog {...dialogProps} open={openForm} />
}
