import React, { useEffect } from 'react'
import { HeroCollection } from '@pcln/hero-collection'
import type { IGoogleAnalyticsTrackingType } from '@pcln/contentful-components'
import { isIHeroCollection } from '@/shared-utils/content-model-type-guards'
import useContentfulVariation from '@/hooks/useContentfulVariation'
import analytics from '@/shared-utils/analytics'
import useSeti from '@/hooks/useSeti'

function handleHeroCollectionSelect(
  analyticsTracking: IGoogleAnalyticsTrackingType
) {
  if (analyticsTracking?.parameters) {
    analytics.fireGA4Event(analyticsTracking.parameters)
  }
}

export default function HeroCollectionWrapper({
  isMobile = false
}: {
  isMobile?: boolean
}) {
  const heroCollectionData = useContentfulVariation(
    'heroCollection',
    isIHeroCollection
  )
  const showHeroCollection =
    useSeti('HP_SEASONAL_HEROCOLLECTION', heroCollectionData !== undefined) ===
      'VARIANT' && heroCollectionData !== undefined
  useEffect(() => {
    const heroCollectionGA4params =
      heroCollectionData?.analyticsTracking?.parameters
    if (heroCollectionGA4params && showHeroCollection) {
      analytics.fireGA4Event(heroCollectionGA4params)
    }
  }, [heroCollectionData, showHeroCollection])
  return showHeroCollection ? (
    <HeroCollection
      heroCollection={heroCollectionData}
      handleSelect={handleHeroCollectionSelect}
      overflowAllowancePxX={isMobile ? 24 : 0}
    />
  ) : undefined
}
