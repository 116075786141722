import React, { useState } from 'react'
import { Box } from 'pcln-design-system'
import StyledHeading from '@/components/StyledHeading'
import MobileTabStrip from '@/components/MobileTabStrip/MobileTabStrip'
import type { ProductType } from '@/types'
import SearchFormProvider from '@/context/SearchFormContext'
import useSeti from '@/hooks/useSeti'
import TabStripExperiment from '@/components/SearchFormExperiment/TabStripExperiment'
import useHeadlineValues from './customHooks/useHeadlineValues'
import getProductCategory from './utils/getProductCategory'
import useFormatHotelHeadline from './customHooks/useFormatHotelHeadline'
import HeroMobile from '../Hotels/Hero/HeroMobile'
import {
  PRODUCT_TABS,
  PRODUCT_TABS_EXPERIMENT,
  PRODUCT_TABS_PACKAGES_RENAMED,
  tabButtons
} from './constants'
import SearchFormDialog from './SearchFormDialog'
import {
  fireTabClickEvent,
  fireSearchButtonClickEvent,
  fireExitMethodEvent,
  fireInputFieldEvent
} from './utils/experimentGA4'
import StyledButton from './utils/Button.styled'

export default function SearchFormMobile({
  currentTab,
  onFormChange,
  showNewMobileTabs
}: {
  currentTab: ProductType
  onFormChange: (productType: ProductType) => void
  showNewMobileTabs?: boolean
}) {
  const mobileValues = useHeadlineValues(currentTab)
  const { hotelHeadline, setLocation } = useFormatHotelHeadline(mobileValues)
  const shouldRenamePackagesTab =
    useSeti('PKG_HOMEPAGE_RENAME_TEST', true) === 'VARIANT'

  const productTabs = shouldRenamePackagesTab
    ? PRODUCT_TABS_PACKAGES_RENAMED
    : PRODUCT_TABS

  const updatedMobileValues = {
    ...mobileValues,
    hotels: {
      ...mobileValues.hotels,
      mobileHeadline: hotelHeadline
    }
  }

  const [openForm, setOpenForm] = useState(false)
  const [formState, setFormState] = useState(
    getProductCategory(currentTab, updatedMobileValues, setLocation)
  )

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      fireExitMethodEvent(currentTab)
    }
    setOpenForm(isOpen)
  }
  const handleOnTabClick = (productType: ProductType) => {
    if (showNewMobileTabs) {
      fireTabClickEvent(productType)
    }
    handleOpenChange(true)
    if (productType === currentTab) {
      return
    }
    const selectedProductCategory = getProductCategory(
      productType,
      updatedMobileValues,
      setLocation
    )
    setFormState(selectedProductCategory)
    onFormChange(productType)
  }

  const handleSearchButtonClick = () => {
    fireSearchButtonClickEvent(currentTab)
    handleOpenChange(true)
  }

  const handleInputClick = () => {
    fireInputFieldEvent(currentTab)
  }

  return showNewMobileTabs ? (
    <Box mb={3}>
      <TabStripExperiment
        initialActiveTab={currentTab}
        onTabClick={handleOnTabClick}
        productTabs={PRODUCT_TABS_EXPERIMENT}
      />
      <SearchFormDialog
        openForm={openForm}
        onOpenChange={handleOpenChange}
        currentTab={currentTab}
        formState={formState}
        onInputClick={handleInputClick}
      />
      <Box px={3} pt={2} pb={3}>
        <StyledButton
          width={1}
          size="extraLarge"
          borderRadius="xl"
          py={0}
          boxShadowSize="xl"
          height={56}
          onClick={() => handleSearchButtonClick()}
        >
          {tabButtons[currentTab]}
        </StyledButton>
      </Box>
    </Box>
  ) : (
    <Box mt={2} mb={3} boxShadowSize="xl" borderRadius="2xl">
      <MobileTabStrip
        initialActiveTab={currentTab}
        onTabClick={handleOnTabClick}
        productTabs={productTabs}
      />
      {currentTab === 'hotels' && <HeroMobile />}
      <SearchFormProvider>
        <Box px={3}>
          {formState.mobileHeadline && (
            <StyledHeading
              tag="h1"
              color="text.heading"
              textStyle="heading3"
              text={formState.mobileHeadline}
            />
          )}
          <Box mt={formState.mobileHeadline ? 3 : 0}>{formState.component}</Box>
        </Box>
        <Box mt={3}>{formState.bumper}</Box>
      </SearchFormProvider>
    </Box>
  )
}
