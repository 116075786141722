import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Text, getPaletteColor, Link } from 'pcln-design-system'
import ProductIcon from '@/components/ProductIcon'
import type { ProductTabType, ProductType } from '@/types'

const IconWrapper = styled(Flex)<{ isActive: boolean }>`
  border: 2px solid
    ${props =>
      props.isActive ? getPaletteColor('primary.base') : 'transparent'};
`

const TabWrapper = styled(Link)`
  &:focus,
  &:visited,
  &:hover {
    text-decoration: none;
  }
`

type MobileTabStripProp = {
  initialActiveTab: ProductType
  onTabClick: (tab: ProductType) => void
  productTabs: ReadonlyArray<ProductTabType>
}

export default function MobileTabStrip({
  initialActiveTab,
  productTabs,
  onTabClick
}: MobileTabStripProp) {
  const [activeTab, setActiveTab] = useState(initialActiveTab)
  return (
    <Flex
      role="tablist"
      aria-label="Types of travel"
      justifyContent="space-around"
      py={2}
      mb="10px"
    >
      {productTabs.map(tab => {
        const isActive = tab.id === activeTab
        return (
          <TabWrapper
            key={tab.id}
            width={1 / productTabs.length}
            role="tab"
            aria-selected={isActive}
            aria-label={`Search for ${tab.id}`}
            id={`tab-${tab.id}`}
            data-testid={tab.autobotID}
            onClick={() => {
              setActiveTab(tab.id)
              onTabClick(tab.id)
            }}
          >
            <IconWrapper
              mx="auto"
              mb={1}
              width={48}
              height={48}
              pt={1}
              bg={isActive ? 'primary.light' : 'background'}
              borderRadius="50%"
              alignItems="center"
              justifyContent="center"
              isActive={isActive}
            >
              <ProductIcon name={tab.id} isActive={isActive} size="32px" />
            </IconWrapper>
            <Text
              fontSize={1}
              textAlign="center"
              lineHeight="16px"
              color="primary"
              fontWeight={isActive ? 'bold' : 100}
            >
              {tab.tabText}
            </Text>
          </TabWrapper>
        )
      })}
    </Flex>
  )
}
