import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Text, getPaletteColor, Button, theme } from 'pcln-design-system'
import ProductIconExperiment from '@/components/ProductIconExperiment'
import type { ProductTabType, ProductType } from '@/types'

const StyledFlex = styled(Flex)`
  gap: 4px;
  ${theme.mediaQueries.md} {
    gap: 12px;
  }
`
const IconWrapper = styled(Flex)`
  width: 100%;
  ${theme.mediaQueries.md} {
    gap: 12px;
  }
`

const TabWrapper = styled(Button)<{ isActive: boolean }>`
  &:focus,
  &:visited,
  &:hover {
    text-decoration: none;
  }
  flex-basis: 20%;
  flex-shrink: 0;
  ${theme.mediaQueries.md} {
    flex-basis: 0;
  }
  &::after {
    content: '';
    display: block;
    height: 3px;
    border-radius: 99px;
    background-color: ${props =>
      props.isActive ? getPaletteColor('text.base') : 'transparent'};
    margin-top: 4px;
    margin-right: 16px;
    margin-left: 16px;
  }
`

const TextWrapper = styled(Text)<{ isActive: boolean }>`
  color: ${props =>
    props.isActive ? getPaletteColor('text.base') : '#496785'};
`

type TabStripProp = {
  initialActiveTab: ProductType
  onTabClick: (tab: ProductType) => void
  productTabs: ReadonlyArray<ProductTabType>
}

export default function TabStripExperiment({
  initialActiveTab,
  productTabs,
  onTabClick
}: TabStripProp) {
  const [activeTab, setActiveTab] = useState(initialActiveTab)
  return (
    <StyledFlex
      role="tablist"
      aria-label="Types of travel"
      justifyContent="center"
      pt={['12px', null, 0]}
      px={[3, null, 0]}
    >
      {productTabs.map(tab => {
        const isActive = tab.id === activeTab
        return (
          <TabWrapper
            key={tab.id}
            variation="link"
            role="tab"
            aria-selected={isActive}
            aria-label={`Search for ${tab.id}`}
            isActive={isActive}
            id={`tab-${tab.id}`}
            data-testid={tab.autobotID}
            onClick={() => {
              setActiveTab(tab.id)
              onTabClick(tab.id)
            }}
            height={[72, null, 56]}
          >
            <IconWrapper
              flexDirection={['column', null, 'row']}
              mx="auto"
              px={[0, null, 3]}
              alignItems="center"
              justifyContent="center"
            >
              <ProductIconExperiment
                name={tab.id}
                isActive={isActive}
                size="32px"
              />
              <TextWrapper
                fontSize={[0, null, 1]}
                textAlign="center"
                lineHeight="16px"
                isActive={isActive}
              >
                {tab.tabText}
              </TextWrapper>
            </IconWrapper>
          </TabWrapper>
        )
      })}
    </StyledFlex>
  )
}
