import { useQuery } from '@tanstack/react-query'
import fetchExtendStayBooking from '@/shared-utils/fetchExtendStayBooking'
import type { ExtendStayTrip } from '@/types'
import fetchTripGroupings from '@/shared-utils/fetchTripGroupings'
import {
  getFirstValidAbandonedSelection,
  getFirstValidTripGrouping
} from '@/components/TripActivity/utils'
import type { TripGrouping } from '@/components/TripGrouping/types'
import analytics from '@/shared-utils/analytics'

function parseExtendStayData(extendStayData: ExtendStayTrip | null) {
  if (extendStayData) {
    return {
      offerToken: extendStayData.offerToken,
      travelStartDateTime: extendStayData.travelStartDateTime,
      travelEndDateTime: extendStayData.travelEndDateTime,
      cityName: extendStayData.cityName,
      stateName: extendStayData.stateName,
      hotelID: extendStayData.hotelID,
      numRooms: extendStayData.numRooms,
      title: extendStayData.title
    }
  }
  return null
}
export default function useTripBookings(
  cguid: string,
  appName: string,
  appVersion: string,
  email: string,
  signedIn: boolean
) {
  const { data: extendStayData = null, error: extendStayError } =
    useQuery<ExtendStayTrip | null>({
      queryKey: [cguid, appName, appVersion, email],
      queryFn: async () => {
        const extendStayBooking = await fetchExtendStayBooking(
          cguid,
          appName,
          appVersion,
          email
        )
        return parseExtendStayData(extendStayBooking)
      },
      staleTime: Infinity,
      enabled: signedIn && !!email
    })

  const { data: tripGroupingData = null, error: tripGroupingError } =
    useQuery<TripGrouping>({
      queryKey: [cguid, appName, appVersion, 'tripGroupings'],
      queryFn: async () => {
        const selections = await fetchTripGroupings(cguid, appName, appVersion)
        const { tripGroupingBooking, tripGroupingAbandonedSelection } =
          getFirstValidTripGrouping(selections.tripGroupings)
        const defaultAbandonedSelection = getFirstValidAbandonedSelection(
          selections.abandonedSelections
        )
        return {
          tripGroupingBooking,
          tripGroupingAbandonedSelection,
          defaultAbandonedSelection
        }
      },
      staleTime: Infinity,
      retry: false
    })

  const {
    tripGroupingBooking = null,
    tripGroupingAbandonedSelection = null,
    defaultAbandonedSelection = null
  } = tripGroupingData || {}

  if (extendStayError) {
    analytics.logError({
      cguid,
      message: 'Error thrown from useQuery',
      error: JSON.stringify(extendStayError),
      service: 'fetchExtendStayBooking'
    })
  }

  if (tripGroupingError) {
    analytics.logError({
      message: 'Error thrown from useQuery',
      error: JSON.stringify(tripGroupingError),
      service: 'fetchTripGroupings'
    })
  }

  return {
    extendStayBooking: extendStayData,
    tripGroupingBooking,
    tripGroupingAbandonedSelection,
    defaultAbandonedSelection
  }
}
