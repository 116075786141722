import React from 'react'
import { Hide } from 'pcln-design-system'
import ExtendStay from '@/components/ExtendStay'
import useBootstrapData from '@/hooks/useBootstrapData'
import TripGrouping from '@/components/TripGrouping/TripGrouping'
import TripActivity from '@/components/TripActivity/TripActivity'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'
import useTripBookings from '@/hooks/useTripBookings'
import useGrowthLoopAudiences from '@/hooks/useGrowthLoopAudiences'

type TripDisplayOrderProps = {
  isLandingPage?: boolean
}

export default function TripDisplayOrder({
  isLandingPage = false
}: TripDisplayOrderProps) {
  const {
    webstats: { clientGUID },
    appName,
    appVersion,
    signInStatus: { signedIn },
    customer: { emailAddress }
  } = useBootstrapData()

  const growthLoopAudiences = useGrowthLoopAudiences(
    emailAddress,
    appName,
    appVersion
  )

  const {
    extendStayBooking,
    tripGroupingBooking,
    tripGroupingAbandonedSelection,
    defaultAbandonedSelection
  } = useTripBookings(clientGUID, appName, appVersion, emailAddress, signedIn)

  if (signedIn) {
    if (extendStayBooking) {
      return (
        <>
          <Hide md mb={4}>
            <ExtendStay {...extendStayBooking} />
          </Hide>
          <TripActivity
            productType={['STAY', 'FLY', 'DRIVE']}
            analyticsCategory={ANALYTICS_CATEGORY_HOME}
            shouldUseSmallerHeading
          />
        </>
      )
    }

    return (
      <TripGrouping
        growthLoopAudiences={growthLoopAudiences}
        isLandingPage={isLandingPage}
        tripGroupingBooking={tripGroupingBooking}
        tripGroupingAbandonedSelection={tripGroupingAbandonedSelection}
        defaultAbandonedSelection={defaultAbandonedSelection}
      />
    )
  }

  return (
    <TripActivity
      productType={['STAY', 'FLY', 'DRIVE']}
      analyticsCategory={ANALYTICS_CATEGORY_HOME}
      shouldUseSmallerHeading={false}
    />
  )
}
