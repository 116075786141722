import React from 'react'
import {
  HotelsDark,
  HotelsGrey,
  FlightsDark,
  FlightsGrey,
  PackagesDark,
  PackagesGrey,
  CarsDark,
  CarsGrey,
  CruisesDark,
  CruisesGrey
} from '@pcln/brand'
import type { ProductType } from '@/types'

type IconSizeType = string | number | null
type ProductIconType = {
  name: ProductType
  isActive: boolean
  size: IconSizeType[] | string | number
}

const icons = {
  hotels: { outline: HotelsGrey, fill: HotelsDark },
  flights: { outline: FlightsGrey, fill: FlightsDark },
  vacations: { outline: PackagesGrey, fill: PackagesDark },
  cars: { outline: CarsGrey, fill: CarsDark },
  cruises: { outline: CruisesGrey, fill: CruisesDark }
} as const

export default function ProductIconExperiment(props: ProductIconType) {
  const { name, isActive, ...iconProps } = props
  const IconType = isActive ? icons[name].fill : icons[name].outline
  return <IconType {...iconProps} />
}
