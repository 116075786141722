import React from 'react'
import styled from 'styled-components'
import { Flex, Hide, theme } from 'pcln-design-system'
import VIP from '@/components/Promos/VIP'
import PriceBreakers from '@/components/Promos/Pricebreakers'
import useContentfulVariation from '@/hooks/useContentfulVariation'
import { isHomepageOrder } from '@/shared-utils/content-model-type-guards'
import PromoFeatureBox from '@/components/PromoFeatureBox'
import HeroCollectionWrapper from '@/components/HeroCollectionWrapper'
import IncredibleTile from '@/components/Promos/IncredibleTile'

const StyledFlex = styled(Flex)`
  gap: 48px;
  ${theme.mediaQueries.md} {
    gap: 32px;
  }
`

type ContentfulOrderedItem = 'heroCollection' | 'topDeals'

function ContentfulItem({
  name,
  signedIn,
  isMobile
}: {
  name: ContentfulOrderedItem
  signedIn: boolean
  isMobile: boolean
}) {
  if (name === 'heroCollection') {
    return <HeroCollectionWrapper isMobile={isMobile} />
  }
  return (
    <Hide md>
      <PromoFeatureBox>
        {signedIn ? <VIP /> : <PriceBreakers />}
        <IncredibleTile contentFulFieldID="incredibleTile" />
      </PromoFeatureBox>
    </Hide>
  )
}

export default function ContentfulOrderWrapper({
  signedIn = false,
  isMobile = false
}: {
  signedIn?: boolean
  isMobile?: boolean
}) {
  const { firstItem = 'heroCollection', secondItem = 'topDeals' } =
    useContentfulVariation('homepageOrder', isHomepageOrder) ?? {}
  return (
    <StyledFlex flexDirection="column">
      <ContentfulItem
        name={firstItem}
        signedIn={signedIn}
        isMobile={isMobile}
      />
      <ContentfulItem
        name={secondItem}
        signedIn={signedIn}
        isMobile={isMobile}
      />
    </StyledFlex>
  )
}
