/**
 * Read the build manifest for an app. Use this to insert link
 * tags into the head of document so that browser can prefetch
 * the app that the customer will be sent to next. Improves
 * performance by letting the browser download ahead of time.
 */
import analytics from './analytics'

type ManifestEntries = Record<string, string>

const insertPrefetchLinks = (data: ManifestEntries) => {
  const head = document.querySelector('head')
  if (head !== null) {
    Object.values(data).forEach((bundle: string) => {
      const link = document.createElement('link')
      link.rel = 'prefetch'
      link.href = bundle
      head.appendChild(link)
    })
  }
}

const retrieveManifest = async (manifestUrl: string) => {
  try {
    const response = await fetch(manifestUrl)
    const manifest = (await response.json()) as ManifestEntries
    return Object.values(manifest).reduce<ManifestEntries>(
      (result, entry, key) => {
        if (entry.match(/\.js$/)) {
          const theResult = result
          theResult[key] = entry
        }
        return result
      },
      {}
    )
  } catch (error) {
    analytics.logError({
      message: `Failed to load ${manifestUrl} for prefetch`
    })
    return {}
  }
}

export { retrieveManifest, insertPrefetchLinks }
