import * as yup from 'yup'
import { formatDate } from '@/shared-utils/date-helper'
import type { LOCATION_SEARCH_TYPE } from '@/types'
import { type ChildrenAges } from '@pcln/traveler-selection'
import { DRIVE, FLY, STAY } from '../constants'
import type { CAR_FORM_STATE_TYPE } from '../types'

export const CHILD_AGE_REQUIRED = "Please enter children's age"
const DATE_RANGE_ERROR =
  'Please make sure the drop-off date is the same or later than the pick-up date'
export const DROPOFF_LOCATION_REQUIRED = 'Please select a drop-off location'
export const INVALID_DATE = 'Please select a valid date'
export const INVALID_DATES = 'Please select valid dates'
export const HOTEL_LOCATION_REQUIRED = 'Please enter a valid location'
export const PICKUP_LOCATION_REQUIRED = 'Please select a pick-up location'
const DISALLOW_INFANT_INTL_FLIGHT =
  'Sorry, we do not offer lap infant seating on international flights'

const intlLocation = (location: LOCATION_SEARCH_TYPE | null) =>
  location &&
  typeof location.countryCode === 'string' &&
  location.countryCode.toUpperCase() !== 'US'

const isInfantFound = (childrenAges: ChildrenAges) =>
  Object.values(childrenAges).some(age => Number(age) === 0)

const isLocationWithInfant = (
  locationObject: LOCATION_SEARCH_TYPE | null,
  childrenAges: ChildrenAges
) => intlLocation(locationObject) && isInfantFound(childrenAges)

function handleInfantTravel(
  this: yup.TestContext,
  formState: CAR_FORM_STATE_TYPE
) {
  const { childrenAges, children } = this.parent
  const { startLocation, endLocation, tripType } = formState
  if (
    tripType.includes(FLY) &&
    (isLocationWithInfant(startLocation, childrenAges) ||
      isLocationWithInfant(endLocation, childrenAges))
  ) {
    return this.createError({
      message: DISALLOW_INFANT_INTL_FLIGHT,
      path: this.path
    })
  }

  const validAgesCount = Object.values(childrenAges || {}).filter(
    age => /^\d+$/.test(String(age)) && Number(age) >= 0
  ).length

  if (
    (tripType.includes(STAY) || tripType.includes(FLY)) &&
    validAgesCount !== children
  ) {
    return this.createError({
      message: CHILD_AGE_REQUIRED,
      path: this.path
    })
  }

  return true
}

function isWithinDateRange(this: yup.TestContext) {
  const { endDate, startDate } = this.parent
  const formattedStartDate = formatDate(startDate)
  const formattedEndDate = formatDate(endDate)

  return new Date(formattedStartDate) <= new Date(formattedEndDate)
}

const searchFormSchema = yup.object().shape({
  travelers: yup.object().shape({
    childrenAges: yup.object().test({
      name: 'childrenAges',
      test() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return handleInfantTravel.call(this, this.options.from[2].value)
      }
    })
  }),
  endDate: yup.string().when('tripType', {
    is: (tripType: string) => tripType === DRIVE,
    then: schema =>
      schema.required(INVALID_DATE).when('startDate', {
        is: (startDate: string) => startDate !== '',
        then: nestedSchema =>
          nestedSchema.test({
            message: DATE_RANGE_ERROR,
            name: 'endDate',
            test: isWithinDateRange
          }),
        otherwise: nestedSchema => nestedSchema.nullable()
      }),
    otherwise: schema => schema.nullable()
  }),
  endLocation: yup
    .object()
    .nullable()
    .when('oneWay', {
      is: true,
      then: schema => schema.nullable().required(DROPOFF_LOCATION_REQUIRED)
    })
    .when('tripType', {
      is: (tripType: string) => tripType.includes(STAY),
      then: schema => schema.nullable().required(HOTEL_LOCATION_REQUIRED)
    })
    .when('tripType', {
      is: (tripType: string) => tripType.includes(FLY),
      then: schema => schema.nullable().required(HOTEL_LOCATION_REQUIRED)
    }),
  hotelEndDate: yup
    .string()
    .nullable()
    .when('tripType', {
      is: (tripType: string) => tripType.includes(STAY),
      then: schema => schema.required(INVALID_DATES)
    })
    .when('tripType', {
      is: (tripType: string) => tripType.includes(FLY),
      then: schema => schema.required(INVALID_DATES)
    }),
  hotelStartDate: yup
    .string()
    .nullable()
    .when('tripType', {
      is: (tripType: string) => tripType.includes(STAY),
      then: schema => schema.required(INVALID_DATES)
    })
    .when('tripType', {
      is: (tripType: string) => tripType.includes(FLY),
      then: schema => schema.required(INVALID_DATES)
    }),
  startDate: yup
    .string()
    .nullable()
    .when('tripType', {
      is: (tripType: string) => tripType === DRIVE,
      then: schema => schema.required(INVALID_DATE)
    }),
  startLocation: yup
    .object()
    .nullable()
    .when('tripType', {
      is: (tripType: string) => tripType === DRIVE,
      then: schema => schema.nullable().required(PICKUP_LOCATION_REQUIRED)
    })
    .when('tripType', {
      is: (tripType: string) => tripType.includes(FLY),
      then: schema => schema.nullable().required(HOTEL_LOCATION_REQUIRED)
    })
})

export default searchFormSchema
