import React from 'react'
import { Box } from 'pcln-design-system'
import type { ProductType } from '@/types'

export default function getProductCategory(productType: ProductType) {
  switch (productType) {
    case 'flights':
      return {
        component: (
          <Box p={4} bg="red" color="text.lightest">
            Flights Form
          </Box>
        )
      }
    case 'cars':
      return {
        component: (
          <Box p={4} bg="yellow">
            Cars Form
          </Box>
        )
      }
    case 'vacations':
      return {
        component: (
          <Box p={4} bg="pink">
            Packages Form
          </Box>
        )
      }

    case 'cruises':
      return {
        component: (
          <Box p={4} bg="green">
            Cruises Form
          </Box>
        )
      }

    default:
      return {
        component: (
          <Box p={4} bg="primary" color="text.lightest">
            Hotels Form
          </Box>
        )
      }
  }
}
