import type { LOCATION_SEARCH_TYPE } from '@/types'
import packagesSearch from '@/shared-utils/packages-search'
import {
  CAR_SEARCH_DATA,
  persistSearchData
} from '@/shared-utils/local-forage-helper'
import fireCarsFormGA4Event from '../ga4'
import { DRIVE, FLY, STAY } from '../constants'
import type { CAR_FORM_STATE_TYPE } from '../types'

export const initialState = {
  travelers: {
    adults: 2,
    childrenAges: {},
    children: 0,
    rooms: 1,
    lapInfants: 0
  },
  endDate: '',
  endLocation: null,
  endTime: '12:00',
  hotelEndDate: '',
  hotelStartDate: '',
  oneWay: false,
  startDate: '',
  startLocation: null,
  startTime: '12:00',
  tripType: DRIVE
} as const

export const fields = [
  'travelers',
  'endDate',
  'endLocation',
  'endTime',
  'hotelEndDate',
  'hotelStartDate',
  'oneWay',
  'startDate',
  'startLocation',
  'startTime',
  'tripType'
] as const

function formatCarsPartnerLocation(location: LOCATION_SEARCH_TYPE | null) {
  if (!location) return null
  if (location.type === 'AIRPORT' || location.type === 'PARTNER_LOC') {
    return location.id
  }

  // Same logic as how drive-search-responsive formats the itemName
  return location.itemName?.replace(/[^a-zA-Z0-9,-]+/g, '%20')
}

export function formatCarsURL({
  endDate,
  endLocation,
  endTime = '12:00',
  oneWay = false,
  startDate,
  startLocation,
  startTime = '12:00'
}: {
  endDate: string
  endLocation: LOCATION_SEARCH_TYPE | null
  endTime: string
  oneWay: boolean
  startDate: string
  startLocation: LOCATION_SEARCH_TYPE | null
  startTime: string
}) {
  const formattedStartLocation = formatCarsPartnerLocation(startLocation)
  const formattedEndLocation = oneWay
    ? formatCarsPartnerLocation(endLocation)
    : formattedStartLocation

  return [
    '/drive/search/r',
    formattedStartLocation,
    formattedEndLocation,
    `${startDate}-${startTime}`,
    `${endDate}-${endTime}`,
    'list'
  ].join('/')
}

export function handleSubmitForCars(values: CAR_FORM_STATE_TYPE) {
  const {
    travelers: { adults, childrenAges, children, rooms },
    endLocation,
    hotelEndDate,
    hotelStartDate,
    tripType,
    startLocation
  } = values
  const multiOccFields = {
    adultCount: adults,
    childrenCount: children,
    childrenAges
  }
  const isPackageSearch = tripType.includes(STAY) || tripType.includes(FLY)
  const hasFlight = tripType.includes(FLY)

  if (isPackageSearch) {
    const basePackageSearchDetails = {
      endLocationObject: endLocation,
      endDate: hotelEndDate,
      startDate: hotelStartDate,
      isPartialStayDateValid: false,
      roomCount: Number(rooms),
      tripType,
      ...multiOccFields
    }

    const flightSearchDetails = {
      startLocationObject: startLocation,
      ...multiOccFields
    }
    const packageSearchDetails = {
      ...basePackageSearchDetails,
      ...(hasFlight ? flightSearchDetails : {})
    }
    packagesSearch(packageSearchDetails)
  } else {
    fireCarsFormGA4Event(initialState, values)
    const url = formatCarsURL(values)
    window.open(url)
  }

  void persistSearchData(CAR_SEARCH_DATA, values)
}
