import analytics from '@/shared-utils/analytics'

const GA4ProductTypes = {
  hotels: 'hotel',
  flights: 'air',
  cars: 'car'
} as const

export default function fireViewSimilarDealsEvent(
  productType: 'hotels' | 'flights' | 'cars',
  isLandingPage = false
) {
  const ga4ProductType = GA4ProductTypes[productType]
  analytics.fireGA4Event({
    event: 'internal_link',
    attributes: {
      product_name: ga4ProductType,
      link_name: 'view_similar_deals',
      page_name: isLandingPage ? 'landing' : 'homepage'
    }
  })
}
