import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Box, Flex, Text, getPaletteColor, Button } from 'pcln-design-system'
import styled from 'styled-components'
import type { ProductTabType, ProductType } from '@/types'
import ProductIcon from '@/components/ProductIcon'

const Wrapper = styled(Flex)`
  position: relative;
`
const IconWrapper = styled(Flex)<{ isActive: boolean }>`
  background: ${props =>
    props.isActive ? 'transparent' : getPaletteColor('background.base')};
`

const TabWithIcon = styled(Button)<{ isActive: boolean }>`
  &:hover {
    text-decoration: none;
  }
  z-index: 1;
  position: relative;
  &:hover ${IconWrapper} {
    border: 1px solid
      ${props =>
        props.isActive ? 'transparent' : getPaletteColor('primary.base')};
  }
`

const Slider = styled(Box)`
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 2px solid ${getPaletteColor('primary.base')};
  background-color: ${getPaletteColor('primary.light')};
`
const MotionSlider = motion.create(Slider)

export default function Tabs({
  initialActiveTab,
  onTabClick,
  productTabs
}: {
  initialActiveTab: ProductType
  onTabClick: (name: ProductType) => void
  productTabs: ReadonlyArray<ProductTabType>
}) {
  const [activeTab, setActiveTab] = useState(initialActiveTab)
  const [hoveredTab, setHoverTab] = useState('')

  return (
    <Wrapper role="tablist" aria-label="Types of travel" mx={3}>
      {productTabs.map(tab => {
        const isActive = tab.id === activeTab
        const isHoveredTab = tab.id === hoveredTab

        return (
          <TabWithIcon
            mr={[0, null, null, 3]}
            width={[1, null, null, 'auto']}
            key={tab.id}
            color="primary"
            variation="link"
            role="tab"
            pr={[2, null, null, 3]}
            borderRadius="full"
            isActive={isActive}
            aria-selected={isActive}
            aria-label={`Search for ${tab.id}`}
            data-testid={tab.autobotID}
            onMouseEnter={() => setHoverTab(tab.id)}
            onMouseLeave={() => setHoverTab('')}
            onClick={() => {
              setActiveTab(tab.id)
              onTabClick(tab.id)
            }}
          >
            {isActive && (
              <MotionSlider
                borderRadius="full"
                layoutId="bubble"
                transition={{ duration: 0.4 }}
              />
            )}
            <Flex
              alignItems="center"
              justifyContent="center"
              style={{ border: '2px solid transparent' }}
            >
              <Box display={['none', null, 'block']}>
                <IconWrapper
                  isActive={isActive}
                  alignItems="center"
                  justifyContent="center"
                  pt="2px"
                  borderRadius="50%"
                  width={40}
                  height={40}
                >
                  <ProductIcon
                    name={tab.id}
                    isActive={isActive || isHoveredTab}
                    size="24px"
                  />
                </IconWrapper>
              </Box>
              <Text
                ml={2}
                mb="2px"
                fontSize={1}
                fontWeight={isActive ? 'bold' : 100}
              >
                {tab.tabText}
              </Text>
            </Flex>
          </TabWithIcon>
        )
      })}
    </Wrapper>
  )
}
