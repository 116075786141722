import config from 'isomorphic-config'
import analytics from '@/shared-utils/analytics'
import isAbortSignalTimeoutSupported from '@/shared-utils/is-abortSignal-supported'
import type { GraphResponse, GrowthLoopAudience } from '@/types'
import hasGraphErrors from '@/shared-utils/graph-utils'
import getAuthorizationToken from './get-authorization-token'
import { jsonContentTypeHeader } from '../server/constants'

type GrowthLoopAudienceApiResponse = {
  authorizedCustomerProfile: { audiences: GrowthLoopAudience[] }
}

type GrowthLoopAudienceGraphReponse =
  GraphResponse<GrowthLoopAudienceApiResponse>

const query = `
  query GrowthLoopAudiencesSignedIn($email: String!, $useDataStoreService: Boolean) {
    authorizedCustomerProfile(email: $email,useDataStoreService: $useDataStoreService) {
      audiences {
        id
        name
      }
    }
  }
`

function isValidGraphQLResponse(
  payload: unknown
): payload is GrowthLoopAudienceGraphReponse {
  if (payload && payload instanceof Object) {
    if ('errors' in payload && Array.isArray(payload.errors)) {
      return true
    }
    if (
      'data' in payload &&
      payload.data &&
      typeof payload.data === 'object' &&
      'authorizedCustomerProfile' in payload.data &&
      payload.data.authorizedCustomerProfile &&
      typeof payload.data.authorizedCustomerProfile === 'object' &&
      'audiences' in payload.data.authorizedCustomerProfile &&
      Array.isArray(payload.data.authorizedCustomerProfile.audiences)
    ) {
      return true
    }
  }

  return false
}

function hasRedeemablePayload(
  payload: unknown
): payload is GrowthLoopAudienceGraphReponse {
  if (payload && payload instanceof Object) {
    if ('data' in payload && payload.data && typeof payload.data === 'object') {
      if (
        'authorizedCustomerProfile' in payload.data &&
        payload.data.authorizedCustomerProfile &&
        typeof payload.data.authorizedCustomerProfile === 'object'
      ) {
        return (
          'audiences' in payload.data.authorizedCustomerProfile &&
          Array.isArray(payload.data.authorizedCustomerProfile.audiences)
        )
      }
    }
  }
  return false
}

async function fetchGrowthLoopAudiences({
  appName,
  appVersion,
  email
}: {
  appName: string
  appVersion: string
  email: string
}) {
  const { url, timeout } = config.client['pcln-graph']

  const commonOptions = {
    method: 'POST',
    signal: isAbortSignalTimeoutSupported()
      ? AbortSignal.timeout(timeout)
      : undefined
  }

  const options = {
    ...commonOptions,
    headers: {
      ...jsonContentTypeHeader,
      'apollographql-client-name': appName,
      'apollographql-client-version': appVersion,
      Authorization: getAuthorizationToken()
    },
    body: JSON.stringify({
      query,
      variables: {
        email,
        useDataStoreService: true
      }
    })
  }

  try {
    const response = await fetch(
      `${url}?gqlOp=getGrowthLoopAudiencesSignedIn`,
      options
    )

    if (!response.ok) {
      const { status } = response
      analytics.logError({
        message:
          'The getGrowthLoopAudiencesSignedIn graph query response status is > 299',
        status
      })
      return []
    }

    const payload = await response.json()

    if (isValidGraphQLResponse(payload)) {
      const payloadHasGraphErrors =
        hasGraphErrors<GrowthLoopAudienceApiResponse>(payload)
      const payloadHasRedeemableData = hasRedeemablePayload(payload)

      if (payloadHasGraphErrors) {
        const firstErrorMessage = payload.errors?.[0]?.message
        const errorMessage = firstErrorMessage || 'Unknown error'
        const errorObjectAsString = JSON.stringify(payload.errors)
        const message =
          'The getGrowthLoopAudiencesSignedIn graph query response has returned errors.'
        analytics.logError({
          message,
          errorMessage,
          appName,
          appVersion,
          errorObjectAsString,
          hasRedeemablePayload: payloadHasRedeemableData
        })
      }

      if (
        payload.data &&
        (!payloadHasGraphErrors || payloadHasRedeemableData)
      ) {
        return payload.data.authorizedCustomerProfile.audiences
      }
    } else {
      analytics.logError({
        message: `The getGrowthLoopAudiencesSignedIn graph response is not as per contract`,
        payload: JSON.stringify(payload)
      })
    }
    return []
  } catch (error) {
    const errorMessage =
      error && error instanceof Error
        ? `${error.message} Error Name: ${error.name}`
        : 'Unknown error'
    analytics.logError({
      message: 'Failed to call the getGrowthLoopAudiencesSignedIn graph query.',
      errorMessage,
      error: JSON.stringify(error)
    })
    return []
  }
}

export default fetchGrowthLoopAudiences
