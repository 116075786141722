import { addDays } from 'date-fns/addDays'
import { parseISO } from 'date-fns/parseISO'
import { format } from 'date-fns/format'
import { differenceInCalendarDays } from 'date-fns/differenceInCalendarDays'
import type { BookedItemApiResponse } from '../TripGrouping/types'

const Product = {
  Flight: 1,
  Hotel: 5,
  Car: 8
} as const

function computeHotelsXSellUrl(destination: string, start: Date, end: Date) {
  return `/relax/in/${destination}/from/${format(
    start,
    'yyyyMMdd'
  )}/to/${format(end, 'yyyyMMdd')}/rooms/1` as const
}

export default function buildContent(booking: BookedItemApiResponse) {
  const startDate = parseISO(booking.travelStartDateTime)
  const endDate = parseISO(booking.travelEndDateTime)

  switch (booking.productType) {
    case 'STAY':
      return {
        xSellTargetId: Product.Car,
        xSellUrl: `/drive/search/r/${booking.cityName},${booking.countryCode}/${
          booking.cityName
        },${booking.countryCode}/${format(
          startDate,
          'yyyyMMdd'
        )}-12:00/${format(endDate, 'yyyyMMdd')}-12:00/list`
      } as const
    case 'FLY': {
      const isSameDay = differenceInCalendarDays(endDate, startDate) < 1
      return {
        xSellTargetId: Product.Hotel,
        xSellUrl: computeHotelsXSellUrl(
          booking.destinationAirportInfo.code,
          startDate,
          isSameDay ? addDays(endDate, 1) : endDate
        )
      } as const
    }
    case 'DRIVE':
      return {
        xSellTargetId: Product.Hotel,
        xSellUrl: computeHotelsXSellUrl(
          booking.pickupLocationCityName,
          startDate,
          endDate
        )
      } as const
    // no default
  }
}
