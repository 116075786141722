import analytics from '@/shared-utils/analytics'
import type { ProductType } from '@/types'
import type { GA4ProductType } from '@/ga4types'
import { tabButtons } from '../constants'

// Map product types to GA4 product types
const productToGA4Map: Record<ProductType, GA4ProductType> = {
  hotels: 'hotel',
  cars: 'car',
  flights: 'air',
  vacations: 'bundle',
  cruises: 'cruise'
}

export const fireInputFieldEvent = (currentTab: ProductType) => {
  analytics.fireGA4Event({
    event: 'internal_link',
    attributes: {
      page_name: 'homepage',
      link_name: 'input_field',
      product_name: productToGA4Map[currentTab],
      type: 'collapsed_search_form'
    }
  })
}

export const fireTabClickEvent = (currentTab: ProductType) => {
  analytics.fireGA4Event({
    event: 'internal_link',
    attributes: {
      page_name: 'homepage',
      link_name: 'search_form_tabs',
      product_name: productToGA4Map[currentTab],
      type: 'collapsed_search_form'
    }
  })
}

export const fireSearchButtonClickEvent = (currentTab: ProductType) => {
  analytics.fireGA4Event({
    event: 'internal_link',
    attributes: {
      page_name: 'homepage',
      link_name: tabButtons[currentTab].toLowerCase().replace(/\s+/g, '_'),
      product_name: productToGA4Map[currentTab],
      type: 'collapsed_search_form'
    }
  })
}

export const fireExitMethodEvent = (currentTab: ProductType) => {
  analytics.fireGA4Event({
    event: 'exit_method',
    attributes: {
      method: 'close',
      page_name: 'homepage',
      product_name: productToGA4Map[currentTab],
      type: 'collapsed_search_form'
    }
  })
}
