import { useQuery } from '@tanstack/react-query'
import fetchGrowthLoopAudiences from '@/shared-utils/fetchGrowthLoopAudiences'
import analytics from '@/shared-utils/analytics'
import type { GrowthLoopAudience } from '@/types'

export default function useGrowthLoopAudiences(
  email: string,
  appName: string,
  appVersion: string
) {
  const { data = [], error } = useQuery<GrowthLoopAudience[]>({
    queryKey: [appName, appVersion, email, 'growthLoopAudiencesSignedIn'],
    queryFn: async () => {
      const growthLoopAudiences = await fetchGrowthLoopAudiences({
        email,
        appName,
        appVersion
      })

      return growthLoopAudiences
    },
    staleTime: Infinity,
    enabled: !!email,
    retry: 0
  })

  if (error) {
    analytics.logError({
      message: 'Error thrown from useQuery',
      error: JSON.stringify(error),
      service: 'fetchGrowthLoopAudiences'
    })
  }

  return data
}
