import { format } from 'date-fns/format'
import { parseISO } from 'date-fns/parseISO'
import type { BookedItemApiResponse } from './types'

function formatISODate(date: string, showTime?: boolean) {
  return format(parseISO(date), `EEE, MMM dd${showTime ? " 'at' h:mm a" : ''}`)
}

export function buildTripGroupingContent(booking: BookedItemApiResponse) {
  switch (booking.productType) {
    case 'STAY':
      return {
        productType: 'STAY',
        headline: `${formatISODate(
          booking.travelStartDateTime
        )} - ${formatISODate(booking.travelEndDateTime)}`,
        locationCopy: booking.cityName,
        subHeadline: `Check-in on ${formatISODate(
          booking.travelStartDateTime
        )}`,
        cityName: booking.cityName,
        itineraryUrl: booking.itineraryUrl
      } as const
    case 'FLY':
      return {
        productType: 'FLY',
        headline: `${formatISODate(
          booking.travelStartDateTime
        )} - ${formatISODate(booking.travelEndDateTime)}`,
        locationCopy: `${booking.originAirportInfo.code} - ${booking.destinationAirportInfo.code}`,
        subHeadline: `Departs ${formatISODate(
          booking.travelStartDateTime,
          true
        )}`,
        cityName: booking.destinationAirportInfo.city,
        itineraryUrl: booking.itineraryUrl
      } as const
    case 'DRIVE':
      return {
        productType: 'DRIVE',
        headline: `${formatISODate(
          booking.travelStartDateTime
        )} - ${formatISODate(booking.travelEndDateTime)}`,
        locationCopy: booking.pickupLocationCityName,
        subHeadline: `Pick-up on ${formatISODate(
          booking.travelStartDateTime,
          true
        )}`,
        cityName: booking.pickupLocationCityName,
        itineraryUrl: booking.itineraryUrl
      } as const
    // no default
  }
}

export function buildTripGroupingContentExperiment(
  booking: BookedItemApiResponse
) {
  switch (booking.productType) {
    case 'STAY': {
      const {
        hotelName,
        hotelInfo,
        cityName,
        stateCode,
        countryCode,
        numberOfDays,
        travelStartDateTime,
        travelEndDateTime,
        itineraryUrl
      } = booking
      return {
        productType: 'STAY',
        headline: hotelName,
        subheadline: `${cityName}, ${
          stateCode || countryCode
        } • ${numberOfDays} night stay`,
        startDateTitle: 'Check-in:',
        startDate: formatISODate(travelStartDateTime),
        endDateTitle: 'Check-out:',
        endDate: formatISODate(travelEndDateTime),
        cityName,
        itineraryUrl,
        imageUrl:
          hotelInfo?.thumbnail?.source ||
          'https://s1.pclncdn.com/design-assets/product-icons/thumbnail-images/product-thumbnail-hotels-lg.jpg?opto&auto=avif,webp'
      } as const
    }

    case 'FLY': {
      const {
        originAirportInfo: {
          city: originCity,
          state: originState,
          code: originCode
        },
        destinationAirportInfo: {
          city: destCity,
          state: destState,
          code: destCode
        },
        tripType,
        travelStartDateTime,
        travelEndDateTime,
        itineraryUrl,
        airlineMarketingCode
      } = booking
      const data = {
        productType: 'FLY',
        headline:
          originCity && originState && destCity && destState
            ? `${originCity}, ${originState} (${originCode}) - ${destCity}, ${destState} (${destCode})`
            : `${originCode} - ${destCode}`,
        startDateTitle: 'Departure:',
        endDateTitle: 'Arrival:',
        startDate: formatISODate(travelStartDateTime),
        cityName: destCity,
        itineraryUrl,
        imageUrl: `https://s1.pclncdn.com/design-assets/fly/carrier-logos/airLogo_${airlineMarketingCode}.png?opto&auto=avif,webp`
      }
      if (tripType === 'RT') {
        return {
          ...data,
          subheadline: 'Round trip flight',
          endDate: formatISODate(travelEndDateTime)
        }
      }
      if (tripType === 'OW') {
        return {
          ...data,
          subheadline: 'One way flight',
          endDate: undefined
        }
      }
      return {
        ...data,
        subheadline: 'Multi destination flight',
        endDate: formatISODate(travelEndDateTime)
      } as const
    }

    case 'DRIVE': {
      const {
        pickupLocationCityName,
        pickupLocationCountryCode,
        travelStartDateTime,
        travelEndDateTime,
        itineraryUrl
      } = booking
      return {
        productType: 'DRIVE',
        headline: 'Rental Car',
        subheadline: `${pickupLocationCityName}, ${pickupLocationCountryCode}`,
        startDateTitle: 'Pick-up:',
        startDate: formatISODate(travelStartDateTime),
        endDateTitle: 'Drop-off:',
        endDate: formatISODate(travelEndDateTime),
        cityName: pickupLocationCityName,
        itineraryUrl,
        imageUrl:
          'https://s1.pclncdn.com/design-assets/product-icons/thumbnail-images/product-thumbnail-cars-lg.jpg?opto&auto=avif,webp'
      } as const
    }
    // no default
  }
}
