import analytics from '@/shared-utils/analytics'
import type { CAR_FORM_STATE_TYPE } from './types'
import { GA4TripTypeMapper } from '../BundleAndSave/utils'

export default function fireCarsFormGA4Event(
  initialState: CAR_FORM_STATE_TYPE,
  submittedState: CAR_FORM_STATE_TYPE
) {
  analytics.fireGA4Event({
    event: 'search',
    attributes: {
      date_change:
        initialState.startDate !== submittedState.startDate ||
        initialState.endDate !== submittedState.endDate
          ? 'y'
          : 'n',
      trip_start_city_change:
        initialState.startLocation !== submittedState.startLocation ? 'y' : 'n',
      trip_end_city_change:
        initialState.endLocation !== submittedState.endLocation ? 'y' : 'n',
      quantity_change:
        initialState.travelers.adults !== submittedState.travelers.adults ||
        initialState.travelers.children !== submittedState.travelers.children ||
        initialState.travelers.rooms !== submittedState.travelers.rooms
          ? 'y'
          : 'n',
      page_name: 'homepage',
      product_name: 'car',
      same_dropoff_location_change:
        initialState.endLocation === submittedState.endLocation ? 'y' : 'n'
    }
  })
}

export function fireItemClickEvent() {
  analytics.fireGA4Event({
    event: 'select_content',
    attributes: {
      type: 'search_form',
      product_name: 'hotel',
      page_name: 'homepage'
    }
  })
}

export function fireBundleAndSaveEvent(tripType: string) {
  const mappedTripType = GA4TripTypeMapper(tripType)
  analytics.fireGA4Event({
    event: 'select_promotion',
    attributes: {
      type: 'add_on',
      product_name: 'car',
      page_name: 'homepage',
      bundle_flag: 'y',
      bundle_type: mappedTripType
    }
  })
}
