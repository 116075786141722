import React, { useState } from 'react'
import { Box, Flex } from 'pcln-design-system'
import type { ProductType } from '@/types'
import TabStripExperiment from './TabStripExperiment'
import { PRODUCT_TABS_EXPERIMENT } from '../SearchForm/constants'
import getProductCategory from './utils/getProductCategory'

export default function SearchFormExperimentWrapper({
  currentTab,
  onFormChange
}: {
  currentTab: ProductType
  onFormChange: (productType: ProductType) => void
}) {
  const [formState, setFormState] = useState(getProductCategory(currentTab))
  const handleOnTabClick = (productType: ProductType) => {
    if (productType === currentTab) {
      return
    }
    const selectedProductCategory = getProductCategory(productType)
    setFormState(selectedProductCategory)
    onFormChange(productType)
  }
  return (
    <Flex mt={3} mb={5} flexDirection="column" style={{ gap: '16px' }}>
      <TabStripExperiment
        initialActiveTab={currentTab}
        onTabClick={handleOnTabClick}
        productTabs={PRODUCT_TABS_EXPERIMENT}
      />
      <Box mx={[0, null, 5]}>{formState.component}</Box>
    </Flex>
  )
}
