import React, { useEffect } from 'react'
import { AppHandoffCard } from '@pcln/trip-activity-components'
import type { GrowthLoopAudience } from '@/types'
import { fireAppHandoffBannerDisplay, fireAppHandoffBannerClick } from './ga4'

type AppHandoffBannerProps = {
  refId?: string
  refClickId?: string
  offerToken: string
}

export function couldShowAppHandoffBanner(
  audiences: GrowthLoopAudience[],
  targetId: string,
  isMobile: boolean
) {
  return (
    isMobile && audiences.some(audience => audience && audience.id === targetId)
  )
}

export default function AppHandoffBanner({
  offerToken,
  refId = '',
  refClickId = ''
}: AppHandoffBannerProps) {
  const appDownloadUrl = `https://priceline.smart.link/olftmpwka?PAGEID=MWEB_HP_UPCOMING_TRIP&offer-token=${offerToken}&refclickid=${refClickId}&refid=${refId}`

  useEffect(() => {
    fireAppHandoffBannerDisplay()
  }, [])

  return (
    <AppHandoffCard
      heading="Get tailored recommendations for your upcoming trip, available exclusively in the app"
      href={appDownloadUrl}
      onClick={fireAppHandoffBannerClick}
    />
  )
}
