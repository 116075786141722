import React from 'react'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'
import AbandonedSelection from '@/components/TripActivity/AbandonedSelection'
import { HighlightedFlex } from '@pcln/brand'
import { CarsBrand, ChevronRight, HotelsBrand, FlightsBrand } from 'pcln-icons'
import styled from 'styled-components'
import {
  Box,
  Text,
  getPaletteColor,
  GenericBanner,
  Flex,
  Link,
  Badge,
  Image,
  Truncate
} from 'pcln-design-system'
import { themeGet } from '@styled-system/theme-get'
import type {
  AbandonedSelection as AbandonedItem,
  BookedItemApiResponse
} from '@/components/TripGrouping/types'
import { buildTripGroupingContentExperiment } from '@/components/TripGrouping/utils'
import fireViewSimilarDealsEvent from './ga4'

const iconMap = {
  hotels: <HotelsBrand />,
  cars: <CarsBrand />,
  flights: <FlightsBrand />
} as const

const StyledTruncate = styled(Truncate)`
  white-space: normal;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  width: 100%;
  ${themeGet('mediaQueries.xl')} {
    width: 202px;
  }
`
const StyledImage = styled(Image)`
  object-fit: contain;
  ${themeGet('mediaQueries.lg')} {
    flex: '1 0 auto';
  }
`

const StyledFlex = styled(Flex)`
  gap: 8px;
`
const RightAlignedChevronRight = styled(ChevronRight)`
  margin-left: auto;
`

// GenericBanner does not allow removing underline from cta text
// Instead, color the underline to match the background color, making it "invisible"
const StyledButtonText = styled(Text)`
  color: ${getPaletteColor('primary.light')};
  text-decoration: underline;
  text-decoration-color: ${getPaletteColor('primary.dark')};
`

function getBannerProduct(productType: 'hotels' | 'cars' | 'flights') {
  switch (productType) {
    case 'hotels':
      return 'hotel'
    case 'cars':
      return 'rental car'
    case 'flights':
      return 'flight'
    // no default
  }
}

export default function GroupedTripExperiment({
  booking,
  abandonedSelection,
  isLandingPage
}: {
  booking: BookedItemApiResponse
  abandonedSelection: AbandonedItem
  isLandingPage?: boolean
}) {
  const formattedBooking = buildTripGroupingContentExperiment(booking)
  const {
    productType,
    imageUrl,
    headline,
    subheadline,
    startDateTitle,
    startDate,
    endDateTitle,
    endDate,
    itineraryUrl = '',
    cityName
  } = formattedBooking || {}

  return (
    formattedBooking && (
      <HighlightedFlex
        shadowOffsetX={24}
        shadowOffsetY={16}
        maxWidth="1216px"
        aria-label="Your trip is coming up!"
        flexDirection={['column', null, null, null, 'row']}
        justifyContent="space-between"
      >
        <StyledFlex
          flexDirection="column"
          width={[1, null, null, null, '340px']}
          p={3}
          mr={[0, null, 3]}
          borderRadius="xl"
          boxShadowSize="sm"
          justifyContent="center"
          color="background.lightest"
        >
          <StyledFlex flexDirection="row" width={1}>
            <StyledImage
              width={84}
              height={88}
              borderRadius="8px"
              p={productType === 'FLY' ? 3 : 0}
              src={imageUrl}
              my="auto"
              style={{
                backgroundColor:
                  productType === 'DRIVE' ? '#e8f2ff' : 'transparent'
              }}
            />
            <Flex flexDirection="column" justifyContent="center">
              <Box mb={1}>
                <Badge colorScheme="secondaryLight">Booking Confirmed</Badge>
              </Box>
              <StyledTruncate textStyle="heading4" ml={1}>
                {headline}
              </StyledTruncate>
            </Flex>
          </StyledFlex>
          <Text textStyle="paragraph">{subheadline}</Text>
          <Box>
            <Flex>
              <Text textStyle="paragraphBold" mr={1}>
                {startDateTitle}
              </Text>
              <Text textStyle="paragraph">{startDate}</Text>
            </Flex>
            {endDate && (
              <Flex>
                <Text textStyle="paragraphBold" mr={1}>
                  {endDateTitle}
                </Text>
                <Text textStyle="paragraph">{endDate}</Text>
              </Flex>
            )}
          </Box>
          <Link
            href={`${itineraryUrl}&openPenny=y`}
            target="_blank"
            variation="subtle"
            size="small"
          >
            <Flex justifyContent="center" alignItems="center">
              <Image
                width={18}
                height={18}
                src="https://s1.pclncdn.com/design-assets/home/recent-activity/penny-sparkle-icon-gradient.svg"
              />
              <Text pl={1}>Get Help</Text>
            </Flex>
          </Link>
          <Link
            href={itineraryUrl}
            target="_blank"
            variation="fill"
            size="small"
          >
            View Trip
          </Link>
        </StyledFlex>
        <Flex
          flexDirection="column"
          justifyContent="flex-end"
          width={[1, null, null, null, 2 / 3, 3 / 4]}
          px={[0, null, 3]}
          pb={1}
          mt={[4, null, null, null, 0]}
        >
          <Text
            color="text.heading"
            textStyle={['heading4', null, 'heading3']}
            mb={3}
          >
            Continue planning your trip to {cityName}
          </Text>
          <Box mb={3}>
            <AbandonedSelection
              useSmallerView
              isTripGroupedSelection
              abandonedSelection={abandonedSelection}
              analyticsCategory={ANALYTICS_CATEGORY_HOME}
            />
          </Box>
          <GenericBanner
            py={2}
            px={3}
            borderRadius="lg"
            alignItems="center"
            justifyContent="flex-start"
            colorScheme="primaryDark"
            iconLeft={iconMap[abandonedSelection.productType]}
            iconRight={<RightAlignedChevronRight />}
            fontSize={['12px', null, '14px']}
            buttonClick={() => {
              fireViewSimilarDealsEvent(
                abandonedSelection.productType,
                isLandingPage
              )
              window.open(abandonedSelection.genericListingsUrl)
            }}
            ctaText={
              <StyledButtonText
                fontSize={['12px', null, '14px']}
                textAlign="left"
              >
                Looking for something else? View{' '}
                {getBannerProduct(abandonedSelection.productType)} deals similar
                to this {abandonedSelection.headline}
              </StyledButtonText>
            }
          />
        </Flex>
      </HighlightedFlex>
    )
  )
}
