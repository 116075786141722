import React, { useEffect, useState } from 'react'
import { Box, Card } from 'pcln-design-system'
import styled from 'styled-components'
import pclnExperimentation from '@pcln/experimentation'
import StyledHeading from '@/components/StyledHeading'
import useSEMSearchHeadline from '@/hooks/useSEMSearchHeadline'
import { GREETING_DESKTOP, vipHeading } from '@/constants/content'
import useCityNameInFormHeadlineForSEM from '@/hooks/useCityNameInFormHeadlineForSEM'
import type { LOCATION_SEARCH_TYPE, ProductType } from '@/types'
import useBootstrapData from '@/hooks/useBootstrapData'
import useSeti from '@/hooks/useSeti'
import SearchFormProvider from '../../context/SearchFormContext'
import Tabs from './Tabs'
import useHeadlineValues from './customHooks/useHeadlineValues'
import getProductCategory from './utils/getProductCategory'
import { PRODUCT_TABS, PRODUCT_TABS_PACKAGES_RENAMED } from './constants'

const StyledCard = styled(Card)`
  border-radius: 24px 24px 6px 6px;
`

export default function SearchFormDesktop({
  currentTab,
  onFormChange
}: {
  currentTab: ProductType
  onFormChange: (productType: ProductType) => void
}) {
  const [location, setLocation] = useState<LOCATION_SEARCH_TYPE | undefined>()
  const {
    customer: { firstName },
    signInStatus: { signedIn }
  } = useBootstrapData()
  const prePopHeadline = useCityNameInFormHeadlineForSEM(location)
  const SEMHeadline = useSEMSearchHeadline()
  const headlineValues = useHeadlineValues(currentTab)

  const headlineWithName = firstName
    ? vipHeading.headlineWithName(firstName)
    : GREETING_DESKTOP
  const defaultHeadline = signedIn
    ? headlineWithName
    : headlineValues.hotels.headline
  const hotelHeadline = prePopHeadline || SEMHeadline || defaultHeadline
  const updatedHeadlineValues = {
    ...headlineValues,
    hotels: {
      ...headlineValues.hotels,
      headline: hotelHeadline
    }
  }

  useEffect(() => {
    if (currentTab === 'hotels')
      pclnExperimentation.fireImpression('HH_PKG_HOMEPAGE_HOTEL_BUTTON')
  }, [currentTab])

  const showMultiHotel =
    useSeti('HH_PKG_HOMEPAGE_HOTEL_BUTTON', false) === 'VARIANT'

  const shouldRenamePackagesTab =
    useSeti('PKG_HOMEPAGE_RENAME_TEST', true) === 'VARIANT'

  const productTabs = shouldRenamePackagesTab
    ? PRODUCT_TABS_PACKAGES_RENAMED
    : PRODUCT_TABS

  const [formState, setFormState] = useState(
    getProductCategory(
      currentTab,
      updatedHeadlineValues,
      setLocation,
      showMultiHotel
    )
  )
  const handleOnTabClick = (productType: ProductType) => {
    if (productType === currentTab) {
      return
    }
    const selectedProductCategory = getProductCategory(
      productType,
      updatedHeadlineValues,
      setLocation,
      showMultiHotel
    )
    setFormState(selectedProductCategory)
    onFormChange(productType)
  }

  return (
    <Box>
      <StyledHeading
        pl={0}
        pb={[16, null, null, null, 4]}
        pr={[0, null, null, null, '130px']}
        tag="h1"
        color="text.heading"
        text={formState.headline}
        mx={[4, null, null, null, 0]}
        textStyle={['heading2', 'heading3', null, 'heading2', null, 'heading1']}
      />
      <Card
        boxShadowSize="xl"
        borderWidth={0}
        borderRadius="2xl"
        minWidth={[null, null, null, '720px']}
        color="background.lightest"
        mx={[4, null, null, null, 0]}
      >
        <StyledCard borderWidth={0}>
          <Box pt={3}>
            <Tabs
              initialActiveTab={currentTab}
              onTabClick={handleOnTabClick}
              productTabs={productTabs}
            />
          </Box>
        </StyledCard>
        <SearchFormProvider>
          <Box p={3} id={`panel-${currentTab}`}>
            {formState.component}
          </Box>
          <Box>{formState.bumper}</Box>
        </SearchFormProvider>
      </Card>
    </Box>
  )
}
