import styled from 'styled-components'
import { Button } from 'pcln-design-system'

export default styled(Button)`
  box-shadow: 0px 2px 4px 0px rgba(0, 104, 239, 0.12),
    0px 1px 1px 0px rgba(0, 104, 239, 0.16),
    0px 0px 1px 0px rgba(0, 104, 239, 0.24),
    0px -1px 0px 0px rgba(0, 104, 239, 0.03),
    0px -1px 0px 0px rgba(0, 68, 153, 0.01),
    0px 4px 24px 0px rgba(0, 68, 153, 0.1),
    0px 4px 24px -4px rgba(0, 68, 153, 0.1),
    0px 12px 32px -2px rgba(0, 68, 153, 0.1);
`
